<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h5 class="table-heading">{{ $t("reports.filter_reports_face") }}</h5>
        <message-card
          alertType="indicator-info"
          :alertTitle="$t('reports.filter_title')"
          :alertText="$t('reports.filter_text_face')"
          id="commentcard"
        ></message-card>
        <Form
          @submit="(values) => generate(values)"
          :validation-schema="schema"
          ref="reportsForm"
        >
          <!-- Location -->
          <div class="row m-b-sm">
            <h6>Location</h6>
            <hr />

            <div
              v-if="siteGroups.length > 0"
              class="col-md-3 col-xs-6 col-xxs-12 m-b-sm"
            >
              <label for="siteGroupFilter" class="form-label">Site Group</label>
              <Multiselect
                v-model="filters.group"
                :class="{ 'multi-dark': $store.state.auth.status.dark }"
                mode="tags"
                placeholder="Select a site group"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple="false"
                :options="siteGroups"
                label="name"
                track-by="id"
                name="siteGroup"
                @select="loadSites"
                @remove="loadSites()"
              />
              <ErrorMessage name="siteGroup" class="error-feedback" />
            </div>

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="siteFilter" class="form-label"
                >{{ $t("events.filters.site") }}
              </label>
              <Multiselect
                v-model="filters.site"
                :class="{ 'multi-dark': $store.state.auth.status.dark }"
                mode="tags"
                placeholder="Select a site"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :multiple="true"
                :options="sites"
                label="name"
                track-by="id"
                name="site"
              />
              <ErrorMessage name="site" class="error-feedback" />
            </div>
          </div>

          <!-- Claim Details -->
          <div class="row m-b-sm">
            <h6>Time</h6>
            <hr />

            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="startDateFilter" class="form-label">{{
                $t("events.filters.start_date")
              }}</label>
              <Field
                type="date"
                name="start_date"
                class="form-control"
                id="startDateFilter"
                v-model="filters.start_date"
                ref="start_date"
                @change="addFocus('startDateFilter')"
              />
              <ErrorMessage name="start_date" class="error-feedback" />
            </div>
            <div class="col-md-3 col-xs-6 col-xxs-12 m-b-sm">
              <label for="endDateFilter" class="form-label">{{
                $t("events.filters.end_date")
              }}</label>
              <Field
                type="date"
                name="end_date"
                class="form-control"
                id="endDateFilter"
                v-model="filters.end_date"
                ref="end_date"
              />
              <ErrorMessage name="end_date" class="error-feedback" />
            </div>
          </div>

          <!--Time -->
          <div class="row m-b-sm">
            <div class="col-12 m-b-sm">
              <div class="col-md-12 reports-container">
                <button class="btn btn-secondary m-r-sm" @click="updateWeek">
                  {{ $t("reports.week") }}
                </button>
                <button class="btn btn-secondary m-h-sm" @click="updateMonth">
                  {{ $t("reports.month") }}
                </button>
                <button class="btn btn-secondary m-h-sm" @click="updateQuarter">
                  {{ $t("reports.quarter") }}
                </button>
                <button class="btn btn-secondary m-h-sm" @click="updateYear">
                  {{ $t("reports.year") }}
                </button>
              </div>
            </div>
          </div>

          <div class="width-fit-content">
            <button
              type="submit"
              :disabled="loading"
              class="btn btn-primary m-r-sm"
              @click="
                showSecondButton = true;
                this.isShowingReports = true;
                this.isShowingTotals = false;
              "
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm m-r-xxs"
              ></span>
              <span>{{ $t("reports.generate_report") }} </span>
            </button>

            <button
              type="reset"
              :disabled="loading"
              class="btn btn-secondary"
              @click.prevent="clearFilter"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm m-r-xxs"
              ></span>
              <span>{{ $t("global.clear_filter") }}</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <!-- Loading -->
  <div v-show="loading" style="width: 100%; text-align: center">
    <div class="spinner-border" style="height: 4rem; width: 4rem" role="status">
      <span class="visually-hidden">{{ $t("events.loading") }}...</span>
    </div>
  </div>

  <div v-show="!loading && claims.length > 0">
    <!-- 1. Claims summary -->
    <div v-show="isShowingReports" class="row space-between">
      <div class="col-xl-3">
        <div class="card widget widget-info-navigation">
          <div class="card-body">
            <div class="widget-info-navigation-container">
              <div class="widget-info-navigation-content">
                <span class="text-muted">{{ $t("reports.num_faces") }}</span
                ><br />
                <span class="text-primary fw-bolder fs-2">{{
                  filteredClaims.length
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2. Charts -->
    <div v-show="isShowingReports" class="row">
      <div class="m-b-sm">
        <button
          class="btn btn-secondary"
          style="float: right"
          @click="toggleShowCharts"
        >
          {{ showCharts === true ? "Hide" : "Show" }} {{ $t("reports.charts") }}
        </button>
      </div>
    </div>
    <div v-show="isShowingReports" class="row m-b-md" v-if="showCharts">
      <div v-if="charts.facesOverTime.show" class="col-xl-12">
        <vue-apex-charts
          type="line"
          :options="charts.facesOverTime.chartOptions"
          :series="charts.facesOverTime.series"
        >
        </vue-apex-charts>
      </div>
    </div>

    <!-- 3. Export btns -->
    <div v-show="isShowingReports" class="flex m-b-sm" style="float: right">
      <div class="col=2" style="margin-left: 20px">
        <button class="btn btn-secondary m-r-sm" @click="exportExcel">
          Export Excel
        </button>
      </div>
      <div class="col=2">
        <button class="btn btn-secondary" @click="exportCSV">Export CSV</button>
      </div>
    </div>

    <!-- 4. Reports Table -->
    <table v-show="isShowingReports" id="reporttable" class="table">
      <thead>
        <tr>
          <th>{{ $t("events.filters.site") }}</th>
          <th>{{ dateFilter }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="claim in filteredClaims"
          :key="'claims-' + claim.id"
          style="vertical-align: middle"
        >
          <td>{{ claim.site.name }}</td>
          <td>
            <timestamp
              :time="claim.date_received"
              format="dd/MM/yyyy"
            ></timestamp>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-show="isClaimsEmpty" class="no-report-container m-t-md">
    <span> No Claims found that match your search results. </span>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ReportsService from "../../services/reports.service";
import Timestamp from "../../components/Timestamp.vue";
import ClientService from "../../services/client.service";
import { DateTime } from "luxon";
import { Chart, registerables } from "chart.js";
import Multiselect from "vue-multiselect";
import { ExportToCsv } from "export-to-csv";
import * as XLSX from "xlsx";
import MessageCard from "../../components/MessageCard.vue";
import VueApexCharts from "vue3-apexcharts";

Chart.register(...registerables);

export default {
  name: "Reports",
  components: {
    Form,
    Field,
    ErrorMessage,
    Timestamp,
    Multiselect,
    MessageCard,
    VueApexCharts,
  },
  props: {
    site: Object,
    group: Number,
    start_date: String,
    end_date: String,
  },
  data() {
    const schema = yup.object().shape({
      site: yup.array().optional().nullable(),
      group: yup.number().optional().nullable(),
      start_date: yup.date().nullable().optional(),
      end_date: yup.date().nullable().optional(),
    });
    return {
      sites: [],
      siteGroups: [],
      schema: schema,
      claims: [],
      filters: {
        site: [],
        start_date: null,
        end_date: null,
        group: null,
      },
      loading: false,
      isClaimsEmpty: false,
      isShowingReports: false,
      isShowingTotals: false,
      siteTotalsList: [],
      totalValue: 0,
      statuses: [],
      charts: {
        facesOverTime: {
          chartOptions: {
            chart: {
              id: "vuechart-facesOverTime",
            },
            xaxis: {
              type: "datetime",
              title: {
                text: "Date",
              },
            },
            yaxis: {
              title: {
                text: "Number of Faces",
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
          },
          series: [],
          show: true,
        },
      },
      showCharts: true,
      siteFilter: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleDataPointSelection(...args) {
      console.log(
        `Selected ${
          this.charts.status.chartOptions.labels[args[2].dataPointIndex]
        }`
      );
      if (
        this.charts.status.chartOptions.labels[args[2].dataPointIndex] ==
        this.statusFilter
      ) {
        this.statusFilter = null;
        return;
      }
      this.statusFilter =
        this.charts.status.chartOptions.labels[args[2].dataPointIndex];
    },
    handleDataPointSelectionSites(...args) {
      if (
        this.charts.site.chartOptions.labels[args[2].dataPointIndex] ==
        this.siteFilter
      ) {
        this.siteFilter = null;
        return;
      }
      this.siteFilter =
        this.charts.site.chartOptions.labels[args[2].dataPointIndex];
    },
    showStatus(claim) {
      this.showingStatus.status = claim.status;
      this.showingStatus.explanation = claim.status_explanation;
      this.showStatus.modal.show();
    },
    async init() {
      await this.loadSites();
      await this.loadSiteGroups();

      if (this.isValid(this.site)) {
        let siteSearch = this.sites.filter((s) => {
          return parseInt(s.id) === parseInt(this.site);
        })[0];
        if (siteSearch) {
          this.filters.site.push(siteSearch);
        }
      }

      if (this.isValid(this.group)) {
        let groupSearch = this.siteGroups.filter((sg) => {
          return parseInt(sg.id) === parseInt(this.group);
        })[0];
        if (groupSearch) {
          this.filters.group = groupSearch;
        }
      }

      if (this.isValid(this.start_date)) {
        this.filters.start_date = this.start_date;
      }
      if (this.isValid(this.end_date)) {
        this.filters.end_date = this.end_date;
      }

      if (this.site || this.start_date || this.end_date) {
        this.generate(this.filters);
      }
    },
    generate(values) {
      this.loading = true;
      this.isClaimsEmpty = false;

      let sites = this.filters.site.map((obj) => obj.id);

      let start_date = values.start_date
        ? DateTime.fromFormat(values.start_date, "yyyy-MM-dd").toFormat(
            "dd/MM/yyyy"
          )
        : null;

      let end_date = values.end_date
        ? DateTime.fromFormat(values.end_date, "yyyy-MM-dd").toFormat(
            "dd/MM/yyyy"
          )
        : null;

      ReportsService.getClaimReport(
        this.$store.state.auth.user.selectedClient,
        {
          site: sites || null,
          start_date: start_date || null,
          end_date: end_date,
          group: this.filters.group?.id || null,
        }
      )
        .then((response) => {
          this.claims = response.data.claims;

          if (this.claims.length === 0) {
            this.isClaimsEmpty = true;
          }

          this.loading = false;
          this.facesOverTimeData();
        })
        .catch((error) => {
          this.$error("Failed to generate report", error);
          this.loading = false;
        });
    },
    facesOverTimeData() {
      this.charts.facesOverTime.show = false;
      if (!this.claims || this.claims.length === 0) return;

      const claimsData = this.claims.reduce((acc, claim) => {
        const date = DateTime.fromSeconds(claim.date_received).toFormat(
          "yyyy-MM-dd"
        );
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      const seriesData = Object.entries(claimsData)
        .map(([date, count]) => ({
          x: new Date(date).getTime(),
          y: count,
        }))
        .sort((a, b) => a.x - b.x);

      this.charts.facesOverTime.series = [{ name: "Faces", data: seriesData }];
      this.charts.facesOverTime.show = true;
    },
    async loadSites(group = null) {
      return ClientService.getClientSites(
        this.$store.state.auth.user.selectedClient,
        false,
        group?.id || null
      )
        .then((response) => {
          this.sites = response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.sites.forEach((site) => {
            if (site.customer_reference) {
              site.name = "(" + site.customer_reference + ") " + site.name;
            }
          });
        })
        .catch((error) => {
          this.$error(this.$t("sites.site_load_error"), error);
        });
    },
    async loadSiteGroups() {
      return ClientService.getClientSiteGroups(
        this.$store.state.auth.user.selectedClient
      )
        .then((response) => {
          this.siteGroups = response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => {
          this.$error(this.$t("sites.site_load_error"), error);
        });
    },
    updateWeek(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local()
        .startOf("week")
        .toFormat("yyyy-MM-dd");
      this.filters.end_date = DateTime.local()
        .endOf("week")
        .toFormat("yyyy-MM-dd");
    },
    updateMonth(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local()
        .startOf("month")
        .toFormat("yyyy-MM-dd");
      this.filters.end_date = DateTime.local()
        .endOf("month")
        .toFormat("yyyy-MM-dd");
    },
    updateQuarter(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local()
        .startOf("quarter")
        .toFormat("yyyy-MM-dd");
      this.filters.end_date = DateTime.local()
        .endOf("quarter")
        .toFormat("yyyy-MM-dd");
    },
    updateYear(event) {
      event.preventDefault();
      this.filters.start_date = DateTime.local()
        .startOf("year")
        .toFormat("yyyy-MM-dd");
      this.filters.end_date = DateTime.local()
        .endOf("year")
        .toFormat("yyyy-MM-dd");
    },
    exportCSV() {
      var data = [];
      for (let claim of this.claims) {
        if (claim.date_received) {
          data.push({
            "Site Name": claim.site.name,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat(
              "dd/MM/yyyy"
            ),
          });
        }
      }

      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: `VARS Claim Report - ${DateTime.local().toFormat("dd/MM/yyyy")}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    },
    exportExcel() {
      var data = [];
      for (let claim of this.claims) {
        if (claim.date_received) {
          data.push({
            "Site Name": claim.site.name,
            "Date Received": DateTime.fromSeconds(claim.date_received).toFormat(
              "dd/MM/yyyy"
            ),
          });
        }
      }
      let workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Claims Report");
      XLSX.writeFile(workbook, "ClaimReport.xlsx");
    },
    clearFilter() {
      this.filters = {
        site: [],
        start_date: null,
        end_date: null,
      };
    },
    toggleShowCharts() {
      this.showCharts = !this.showCharts;
    },
    isValid(value) {
      if (value === null) return false;
      if (value === undefined) return false;

      if (typeof value === "string") {
        if (value.length < 1) return false;
      }

      return true;
    },
    sortDateField() {
      if (this.sort_by === "date_received") {
        this.claims.sort((a, b) => {
          if (a.date_received > b.date_received) {
            return -1;
          }
          if (a.date_paid < b.date_paid) {
            return 1;
          }
          return 0;
        });
      } else {
        this.claims.sort((a, b) => {
          if (a.date_paid > b.date_paid) {
            return -1;
          }
          if (a.date_paid < b.date_paid) {
            return 1;
          }
          return 0;
        });
      }
    },
  },
  computed: {
    dateFilter: function () {
      return "Date Received";
    },
    filteredClaims: function () {
      let filteredClaims = this.claims;
      if (this.siteFilter !== null) {
        filteredClaims = filteredClaims.filter(
          (claim) => claim.site.name === this.siteFilter
        );
      }
      return filteredClaims;
    },
  },
};
</script>

<style scoped>
td > span {
  display: block;
}

.btn {
  padding: 10px 20px;
}

.table-heading {
  padding-top: 0;
  margin-bottom: 20px;
}

.reports-container {
  height: fit-content;
  margin-top: 25px;
}

.form-check-label {
  color: #cdc4d1;
  font-size: 16px;
  font-weight: 100;
}

.form-check-input:checked + .form-check-label {
  color: white;
}

.form-check-input {
  border-radius: 0;
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

*:not(.form-switch) > .form-check-input:not(:checked) {
  background: #cdc4d1;
  border: 1px solid #cdc4d1;
}

.btn-secondary:focus {
  background-color: #598ef7;
  border-color: #598ef7;
}

.no-report-container {
  width: 100%;
  text-align: center;
  font-style: italic;
  color: #ff9500;
  font-size: 16px;
}

.width-fit-content {
  margin: auto;
  margin-top: 25px;
}

.temp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.temp:hover {
  cursor: pointer;
}

.modal-sub-title {
  font-weight: 100;
}

.form-checkbox {
  padding: 5px;
  margin-right: 10px;
}

.checkbox-container {
  font-weight: 300;
  margin-top: 1rem;
}

.checkbox-container input {
  width: 12px;
  height: 12px;
}

.display-none {
  display: none;
}

#statusFilter input {
  border: none;
}

@media (min-width: 1440px) {
  .col-12 {
    width: 57%;
  }
}
</style>
