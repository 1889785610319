import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class ClientService {
  getClientSites(
    client,
    camerasOnly = false,
    group_id = null,
    product_id = null,
    project = null
  ) {
    let params = new URLSearchParams({
      hascameras: camerasOnly,
    });
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    if (product_id !== null && product_id !== -1) {
      params.append("product_id", product_id);
    }
    if (project !== null && project !== -1) {
      params.append("project", project.join(","));
    }

    return axios.get(
      "https://api.varsanpr.com/api/clients/" +
        client +
        "/sites?" +
        params.toString(),
      { headers: authHeader() }
    );
  }

  getClientSiteGroups(client) {
    return axios.get(
      "https://api.varsanpr.com/api/clients/" + client + "/sitegroups",
      { headers: authHeader() }
    );
  }

  getClients() {
    return axios.get(`${API_URL}client`, { headers: authHeader() });
  }
}

export default new ClientService();
